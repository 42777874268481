import "./App.css";
import travel_01 from "./assets/sunset-at-penang-bridge.jpg";
import travel_02 from "./assets/komtar-penang.jpg";
import travel_03 from "./assets/kek-lok-si.jpg";
import Hero from "./components/Hero.js";
import Slider from "./components/Slider";
import Navbar from "./components/Navbar";

const navbarLinks = [
  { url: "#", title: "Home" },
  { url: "#", title: "Trips" },
  { url: "#", title: "Rewards" },
];
function App() {
  return (
    <div className="App">
      <Navbar navbarLinks={navbarLinks} />
      <Hero imageSrc={travel_01} />
      <Slider
        imageSrc={travel_02}
        title={"Pearl of Orient"}
        subtitle={"Explore the most culturally rich state in the entire nation"}
      />
      <Slider
        imageSrc={travel_03}
        title={"Island of Diversity"}
        subtitle={"One of the biggest tourism attraction places in the world"}
        flipped={true}
      />
    </div>
  );
}

export default App;
